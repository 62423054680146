import React, { useEffect, useRef } from "react"
import Footer from "../../components/footer"
import "../../styles/cloud-service-provider.less"
// import { graphql } from "gatsby"
import { ConsultationPopButton } from "../../components/form-biz"
import { Button } from "../../components/ui"
import SEO from "../../components/SEO"
import Map from "../../components/map"
import { Solution } from "../products/bare-metal-cloud"
import { SolutionTopBanner } from "../../components/block"
const macy = typeof window !== "undefined" ? require("macy") : function () {}
const loadText = require("src/utils").loadText
const data = loadText("cloud-service-providers")
const colors = [
  "linear-gradient(#FFFDF2, #FFF9E1)",
  "linear-gradient(#F2FDFF, #E0F9FF)",
  "linear-gradient(#F5F6FF, #E7EAFF)",
  "linear-gradient(#FAF4FF, #F4E6FF)",
  "linear-gradient(#F2FBFF, #E1F5FF)",
  "linear-gradient(#F1FBFB, #DEF6F5)",
]
const Feature = () => {
  const container = useRef()
  useEffect(() => {
    new macy({
      container: container.current,
      columns: 2,
      margin: 24,
      breakAt: {
        760: {
          columns: 1,
        },
      },
    })
  }, [])
  return (
    <div className="csp-feature">
      <div className="font-36 semi-bold t-color">{data.features.title}</div>
      <div className="description font-20 mt-24">
        {data.features.description}
      </div>
      <div className="content page-middle" style={{ padding: "0 108px" }}>
        <div ref={container} className="content-container mt-48">
          {data.features.content.map(({ name, description }, i) => (
            <div
              style={{ background: colors[i % colors.length] }}
              className="csp-feature-item"
            >
              <img
                src={`/solution/cloud-service-provider/icon-${i + 1}.svg`}
                alt={name}
              />
              <div className="semi-bold font-28 mt-32 t-color">{name}</div>
              <div className="content-description mt-32 font-20">
                {description}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="csp-feature-btn mt-24">
        {/* <Button size="l"></Button> */}
        <ConsultationPopButton source="cloudServiceProvider">
          {data.features.buttonText}
        </ConsultationPopButton>
      </div>
    </div>
  )
}
const MapContainer = () => {
  return (
    <div className="csp-map">
      <div
        className="font-36 semi-bold t-color block-middle text-center"
        style={{ maxWidth: "750px" }}
      >
        {data.map.title}
      </div>
      <div className="mt-64">
        <Map />
      </div>
    </div>
  )
}
const Reliability = () => (
  <div className="csp-reliability">
    <div className="font-36 semi-bold t-color">{data.reliability.title}</div>
    <div className="csp-reliability-itemContainer ">
      {data.reliability.content.map(({ name, content }, i) => (
        <div className="csp-reliability-item mt-56 clearfix">
          <div className="imgContainer">
            <img
              src={`/solution/cloud-service-provider/reliability-${i + 1}.jpg`}
              className="only-desktop"
              alt={name}
            />
            <img
              src={`/solution/cloud-service-provider/reliability-m-${
                i + 1
              }.jpg`}
              className="only-mobile"
              alt={name}
            />
            <div
              className="only-mobile name font-28 semi-bold"
              dangerouslySetInnerHTML={{ __html: name }}
            ></div>
          </div>
          <div className="textContainer">
            <div className="only-desktop font-28 semi-bold t-color mt-24">
              {name}
            </div>
            <ul className="mt-24 font-20">
              {content.map(c => (
                <li>
                  {/* <img
                    src="/solution/cloud-service-provider/check.svg"
                    alt="check"
                  /> */}
                  {c}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}
    </div>
    <div className="csp-reliability-btn mt-64">
      <ConsultationPopButton source="cloudServiceProvider">
        {data.features.buttonText}
      </ConsultationPopButton>
    </div>
  </div>
)
export default function Home() {
  return (
    <div className="csp">
      <SEO {...data.seo} featuredImage="/featuredImage/cloud-service.jpg" />
      <SolutionTopBanner
        {...data.topBanner}
        className="csp-banner"
        backgroundAnimated={true}
        source="cloudServiceProvider"
        bgColor="linear-gradient(360deg, #F2FDFF 0%, #E0F9FF 100%)"
        bgPic="/solution/cloud-service-provider/topBanner-bg.svg"
        bannerPic={{
          src: `/solution/cloud-service-provider/topBanner-m-bg.svg`,
        }}
        pic={{
          src: "/solution/cloud-service-provider/topBanner-pic.png",
          style: {
            position: "absolute",
            bottom: "103px",
            right: "108px",
          },
        }}
      />
      <Feature />
      <MapContainer />
      <Reliability />
      <Solution {...data.solution} />
      <Footer />
    </div>
  )
}
